import http from "../utils/http"


/**
 * 专题详情
 * @param {object} params
 */
export function topicDetail(params) {
    return http({
        url: "/topic/api/topic/detail",
        data: params
    })
}

export function topicGoodsList(params) {
    return http({
        url: "/topic/api/topicgoods/page",
        data: params
    })
}
