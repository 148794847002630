<template>

  <div>

      <div class="banner">
        <el-carousel height="500px" arrow="hover" v-loading="loading">
          <el-carousel-item v-for="item in topic.slider" :key="item.id">
            <el-image :src="$img(item.image)" fit="cover" @click="$router.pushToTab(item.link)"/>
          </el-carousel-item>
        </el-carousel>
      </div>

    <div style="max-width: 1920px;min-width:1200px;margin: 0 auto;">
      <div :style="bgStyle" v-html="topic.content"></div>
    </div>
    <div style="width: 1200px;margin: 0 auto;margin-top: 20px;">
      <ul class="goods-list">
        <li v-for="(item,index) in goodsList" :key="index"
            @click="$router.pushToTab({ path: '/sku-' + item.sku_id })"
            :style="goodsStyle(index)"
        >
          <div class="img-wrap">
            <img alt="商品图片" :src="$img(item.sku_image, { size: 'mid' })">
          </div>
          <h3>{{ item.goods_name }}</h3>
          <p class="ns-text-color">&nbsp;</p>
          <!--p class="desc">{{item.introduction}}</p-->
          <p class="price ns-text-color">
            <span class="num">{{ item.topic_price }}元</span>
            <del>{{ item.price }}元</del>
          </p>
        </li>
      </ul>
    </div>
  </div>

</template>

<script>
import {topicDetail, topicGoodsList} from "@/api/topic";

export default {
  name: "topic_detail",
  components: {},
  data: () => {
    return {
      id: 0,
      topic: {},
      loading: true,
      bgStyle: {},
      goodsList: [],
    }
  },
  created() {
    this.id = this.$route.path.replace("/promotion/topic-", "")
    this.load()
    this.topicGoodsList()

  },
  methods: {
    mainStyle(){
      let width = window.innerWidth;
      let marginWidth = (1920-width)/2;
      if(width >= 1920){
        return {}
      }
      return  {width:"1920px",marginLeft:-marginWidth+"px"};
    },
    goodsStyle(index){
      if(index%5 == 0){
        return {
          marginLeft:0,
        }
      }
    },
    load() {
      this.loading = true;
      topicDetail({topic_id: this.id}).then(resp => {
        let topic = resp.data;
        topic.content = topic.content.replace("&nbsp;","");
        this.topic = topic;
        if (topic.bg_color) {
          this.bgStyle.backgroundColor = topic.bg_color;
        }
        if (topic.bg_img) {
          this.bgStyle.backgroundImage = 'url(' + this.$img(topic.bg_img) + ')';
          switch (topic.bg_type) {
            case 0:
              this.bgStyle.backgroundRepeat = "no-repeat";
              break;
            case 1:
              this.bgStyle.backgroundRepeat = "repeat-x";
              break;
            case 2:
              this.bgStyle.backgroundRepeat = "repeat-y";
              break;
          }
        }
        this.bgStyle = {
          ...this.bgStyle,
          ...this.mainStyle(),
        }


        this.loading = false;
      })

    },
    topicGoodsList() {
      topicGoodsList({topic_id: this.id, page_size: 100}).then(resp => {
        this.goodsList = resp.data.list;
      })
    },

  }
}
</script>
<style lang="scss" scoped>


.goods-list {
  display: flex;
  flex-wrap: wrap;
}

.goods-list li {
  width: 224px;
  background: #fff;
  cursor: pointer;
  padding: 15px 0;
  margin-bottom: 20px;
  margin-left: 20px;
  height: 265px;
}


.goods-list li:hover {
  z-index: 2;
  -webkit-box-shadow: 0 15px 30px rgba(0, 0, 0, 0.1);
  box-shadow: 0 15px 30px rgba(0, 0, 0, 0.1);
  -webkit-transform: translate3d(0, -2px, 0);
  transform: translate3d(0, -2px, 0);
}


.goods-list li.empty {
  background: #F5F5F5;
}

.goods-list li .img-wrap {
  width: 160px;
  height: 160px;
  margin: 0 auto 18px;
  text-align: center;
  line-height: 160px;
}

.goods-list li .img-wrap.empty {
  background: #ebf8fd;
  color: #88c4dc;
}

.goods-list li .img-wrap img {
  max-width: 100%;
  max-height: 100%;
}

.goods-list li h3 {
  font-size: 14px;
  text-align: center;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  margin: 5px 15px;
}

.goods-list li .desc {
  margin: 0 30px 10px;
  height: 20px;
  font-size: 12px;
  color: #b0b0b0;
  text-align: center;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.goods-list li .price {
  margin: 0 10px 14px;
  text-align: center;
}

.goods-list li .price del {
  margin-left: .5em;
  color: #b0b0b0 !important;
}

.banner {
  height: 500px;
  width: 100%;

  .el-image {
    width: 100%;
    height: 100%;
  }
}

</style>
